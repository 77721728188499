<template>
	<div class="login-register_area" style="padding: 20px 0px 0px 0px">
		<div class="container">
			<div class="row col-xs-12">
				<div
					class="col-md-7 col-lg-7 col-sm-12 col-xs-12 center-block"
					style="margin: auto"
				>
					<form action class="form" @submit.prevent="checkVerified()">
						<div class="login-form" style="padding: 0px; padding-bottom: 30px">
							<div class="col-xs-12 banner" style="margin-bottom: 30px">
								<img
									src="assets/img/logo/logo-payforu.svg"
									style="display: block; margin: auto"
									class="img-fluid"
									alt="PayForU"
								/>
							</div>

							<div class="row">
								<div class="col-12 text-center">
									<h2 style="padding: 0 10px; line-height: 1.1">
										Paga fácil, cómodo y seguro
									</h2>
								</div>
							</div>

							<br />
							<div class="container">
								<div class="row">
									<div
										class="col-md-8 center-block"
										style="padding-top: 20px; margin: auto"
									>
										<label>Comercio afiliado *</label>
										<select
											class="form-control"
											@change.prevent="getMethods()"
											required
											v-model="seller"
										>
											<option value="" selected disabled>
												Por favor, selecione un comercio
											</option>
											<option v-for="form in forms" :key="form.id" :value="form">
												{{ form.form_name }}
											</option>
										</select>
									</div>
									<!--<div
                    class="col-md-8 center-block"
                    style="padding-top: 20px; margin:auto;"
                  >
                    <label>Moneda *</label>
                    <select
                      class="form-control"
                      @change="getMethods"
                      style="width: 100%;"
                      required
                      v-model="currency"
                    >
                      <option value="USD" selected>Dólar (USD)</option>
                      <option value="VES">Bolívar (VES)</option>
                    </select>
                  </div>-->
									<div
										class="col-md-12"
										style="padding-top: 20px"
										v-if="paymentOptions != ''"
									>
										<div class="container">
											<div class="row">
												<div class="paymentCont" style="width: 100%">
													<div class="headingWrap">
														<h3 class="headingTop text-center">Métodos de Pago</h3>
														<h4 class="headingTop text-center">
															Selecciona el método de pago que quieres utilizar
														</h4>
													</div>
													<div class="paymentWrap" style="width: 80%; margin: 0 auto">
														<div
															class="btn-group paymentBtnGroup"
															style="justify-content: center"
															data-toggle="buttons"
														>
															<div
																class="row center-block"
																style="margin: 0 auto; justify-content: center"
															>
																<div
																	class
																	style="width: fit-content"
																	v-for="option in paymentOptions"
																	:key="option.id"
																>
																	<div
																		class="btn paymentMethod"
																		@click.prevent="setActive(option.id)"
																		:class="{ active: isActive(option.id) }"
																		style="
																			background-size: contain;
																			width: 100%;
																			min-width: 140px;
																		"
																	>
																		<div
																			class="method"
																			style="background-size: contain"
																			@click.prevent="
																				setMethod(option.id, option.paop_name)
																			"
																			:style="{
																				'background-image':
																					'url(' + option.paop_icon + ')',
																			}"
																		></div>
																		<input type="hidden" v-model="method" />
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										v-if="method == 46"
										class="col-md-8 center-block"
										style="padding-top: 5px; margin: auto"
									>
										<div class="row">
											<a
												@click.prevent="showZelleData()"
												style="padding-top: 20px; margin: auto; text-transform: initial"
												><i class="fa fa-money"></i> Información de pago</a
											>
										</div>
									</div>
									<div
										class="col-md-8 center-block"
										style="padding-top: 20px; margin: auto"
									>
										<label>Monto *</label>
										<!--<input
                      type="number"
                      placeholder="0.00"
                      required
                      v-model="amount"
                    />-->
										<money
											v-model="amount"
											required
											v-bind="money"
											class="form-input input-lg"
										>
										</money>
									</div>
									<div
										class="col-md-8 center-block"
										style="padding-top: 20px; margin: auto"
									>
										<label>Descripción *</label>
										<textarea
											type="text"
											required
											placeholder="Ingresa la descripción del pago"
											maxlength="140"
											style="width: 100%; padding: 10px"
											v-model="description"
										></textarea>
									</div>
									<div class="col-12" style="padding-top: 20px">
										<div class="row">
											<button
												class="ht-btn ht-btn--round"
												@click.prevent="clean()"
												style="float: left; margin: auto; background-color: #007bff"
											>
												Limpiar
											</button>
											<button
												type="submit"
												style="float: right; margin: auto"
												class="ht-btn ht-btn--round"
											>
												Pagar
											</button>
										</div>
									</div>
									<div
										class="col-md-8 center-block"
										style="padding-top: 20px; margin: auto"
									>
										<h4 class="text-center">
											No almacenaremos tus datos personales de cuentas bancarias o
											tarjetas, tu información está segura.
										</h4>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'

	let urlCheckUsername = process.env.VUE_APP_AUTH_API + 'api/user/'
	let urlTransactionByCode = process.env.VUE_APP_PAYFORU_API + 'api/transaction-by-code/'
	let urlGetPayForUSellers = process.env.VUE_APP_CART_API + 'api/payforu-form-active'

	export default {
		data: () => ({
			config: {
				headers: {
					Authorization: `Bearer ` + sessionStorage.getItem('token'),
				},
			},
			name: '',
			lastname: '',
			form_url: '',
			form_image:
				'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/user-icon-placeholder.png',
			currency: '',
			form_cover: '',
			form_url: '',
			form_name: '',
			transaction: [],
			currency: 'USD',
			amount: 0,
			method: '',
			money: {
				decimal: ',',
				thousands: '.',
				prefix: '',
				suffix: '',
				precision: 2,
				masked: false,
			},
			methodName: '',
			activeItem: '',
			paymentOptions: '',
			description: '',
			forms: '',
			seller: '',
			stripeCheck: false,
			isValidated: false,
			error: false,
			showForm: false,
			date: new Date().toISOString().split('T')[0],
			token: '',
			errorMessage: '',
			loading: false,
			stripePublishableKey: process.env.VUE_APP_STRIPE_PK_LIVE,
			card: {
				number: '',
				cvc: '',
				exp: '',
			},
			errors: [],
		}),
		name: 'PaySection',

		created() {
			this.getPayForUSellers()
			this.checkLogin()
		},

		methods: {
			async checkLogin() {
				if (sessionStorage.getItem('token') == null) {
					this.$swal({
						title: 'Iniciar Sesión',
						text: 'Antes de acceder al formulario de pago inicia sesión',
						timer: 4000,
						showCancelButton: false,
						showConfirmButton: false,
					})
					this.$swal.showLoading()
					this.$router.push('/login')
				} else {
					this.token = sessionStorage.getItem('token')
					this.getUserData()
				}
			},

			validate() {
				var cardNumber = document.getElementById('cardNumber').value
				var cardCVC = document.getElementById('cardCVC').value
				var cardExp = document.getElementById('cardExp').value
				this.errors = []
				let valid = true
				if (cardNumber == '') {
					valid = false
					this.errors.push('El número de la tarjeta es requerido')
				}
				if (cardCVC == '') {
					valid = false
					this.errors.push('El CVC es requerido')
				}
				if (cardExp == '') {
					valid = false
					this.errors.push('La fecha de expiración es requerida')
				}
				if (valid) {
					this.card.number = cardNumber
					this.card.cvc = cardCVC
					this.card.exp = cardExp
					this.isValidated = true
					//this.createToken();
				}
				return valid
			},

			isActive(menuItem) {
				return this.activeItem === menuItem
			},

			setActive(menuItem) {
				this.activeItem = menuItem
			},

			clean() {
				this.amount = ''
				this.seller = ''
				this.method = ''
				this.description = ''
				this.activeItem = ''
			},

			setMethod(paop_id, paop_name) {
				this.method = paop_id
				this.methodName = paop_name
				console.log(paop_id, paop_name)
			},

			getMethods() {
				// this.$swal({
				//   title: "Espere...",
				//   text: "Cargando métodos de pago",
				//   icon: "info",
				//   timer: 2000,
				//   showCancelButton: false,
				//   showConfirmButton: false
				// });
				// this.$swal.showLoading();
				this.money.prefix = this.currency + ' '

				let urlGetMethods =
					process.env.VUE_APP_STORE_API +
					'api/currency/' +
					this.currency +
					'/payment-option/users/' +
					this.seller.user_id
				const header = {
					headers: {
						Accept: 'application/json',
						Authorization: `Bearer ` + sessionStorage.getItem('token'),
						'Content-Type': 'application/json',
					},
				}
				axios
					.get(urlGetMethods, header)
					.then((response) => {
						console.log(response.data.currency.payment_options)
						this.paymentOptions = response.data.currency.payment_options
					})
					.catch((error) => {
						console.log(error)
						this.error = true
					})
			},

			async getUserData() {
				this.isVerified = false
				let urlCheckVerified = process.env.VUE_APP_AUTH_API + 'api/auth/user'
				axios
					.get(urlCheckVerified, this.config)
					.then((response) => {
						//console.log(response.data);
						this.email = response.data.email
						this.userVerification = response.data.information.usin_verification
						if (this.userVerification == 'verified') {
							this.isVerified = true
						}
					})
					.catch((error) => {
						this.$swal({
							title: 'Parece que ocurrió un error...',
							text: 'Comprueba tu conexión de internet e intenta de nuevo',
							showCancelButton: false,
							showConfirmButton: true,
						}).then((result) => {
							if (result.isConfirmed) {
								location.reload()
							}
						})
						this.$swal.showValidationMessage(error)
						console.log(error)
						this.error = true
					})
			},

			checkVerified() {
				if (this.method == 41 && this.userVerification != 'verified') {
					if (this.userVerification == 'unverified') {
						this.$swal({
							imageUrl: 'https://vistapointe.net/images/lock-4.jpg',
							imageWidth: 200,
							title: `¡Hola ` + sessionStorage.getItem('name') + `!`,
							html: `Antes de continuar, iniciaremos un proceso rutinario de verificación de datos. <br>
	             Esto nos permite mantener una relacion más segura contigo`,
							showCancelButton: false,
							confirmButtonColor: '#003d74',
							showConfirmButton: true,
							confirmButtonText: 'Aceptar',
						}).then((result) => {
							if (result.isConfirmed) {
								this.$router.push({
									name: 'VerifyUser',
									query: {
										email: this.email,
									},
								})
							}
						})
					} else if (this.userVerification == 'to be checked') {
						this.$swal({
							imageUrl:
								'https://think.kera.org/wp-content/uploads/2018/11/hourglass-800x500.jpg',
							imageWidth: 400,
							imageHeight: 200,
							title: `¡Hola ` + sessionStorage.getItem('name') + `!`,
							html: `Hemos recibido tu solicitud de verificación. <br>
	             Te contactaremos cuando el proceso finalice satisfactoriamente.<br>
	             Este proceso puede tardar más de 24 horas.`,
							showCancelButton: false,
							confirmButtonColor: '#003d74',
							showConfirmButton: true,
							confirmButtonText: 'Aceptar',
						}).then((result) => {
							if (result.isConfirmed) {
								this.$router.push('/')
							}
						})
					} else if (this.userVerification == 'rejected') {
						this.$swal({
							title: `¡Estimado ` + sessionStorage.getItem('name') + `!`,
							html: `Te informamos que el registro de tus datos no superó el proceso de verificación debido a que dichos datos no cumplen con nuestros Términos y Condiciones y Políticas de Privacidad. <br>
	             Para obtener más información te invitamos a contactar a nuestro equipo de atención al cliente a través de: servicioslikeugroup@gmail.com`,
							showCancelButton: false,
							confirmButtonColor: '#003d74',
							showConfirmButton: true,
							confirmButtonText: 'Aceptar',
						}).then((result) => {
							if (result.isConfirmed) {
								this.$router.push('/')
							}
						})
					}
				} else {
					this.paymentProcess(
						this.method,
						this.token,
						this.amount,
						this.currency,
						this.description,
						this.seller.user_id,
						13
					)
				}
			},

			getPayForUSellers() {
				axios
					.get(urlGetPayForUSellers, {
						headers: {
							Authorization: `Bearer ` + sessionStorage.getItem('token'),
						},
					})
					.then((response) => {
						//console.log(response.data);
						this.forms = response.data.payforuform
					})
					.catch((error) => {
						console.log(error)
						this.error = true
					})
			},

			getTransaction(code) {
				axios
					.get(urlTransactionByCode + code, {
						headers: {
							Authorization: `Bearer ` + sessionStorage.getItem('token'),
						},
					})
					.then((response) => {
						//console.log(response.data);
						this.transaction = response.data.transaction
						let status = ''
						switch (this.transaction.tran_status) {
							case 'pending':
								status = 'Pendiente por conciliar'
								break
							case 'success':
								status = 'Transacción completada satisfactoriamente'
								break
							case 'failed':
								status = 'Transacción fallida'
								break

							default:
								status = 'n/a'
								break
						}
						this.$swal({
							title: '¡Transacción recibida!',
							icon: 'success',
							html:
								'<p style="text-align: initial;font-size: medium;">Monto: <strong>' +
								this.transaction.gateway_payment_method.currency.curr_code +
								' ' +
								this.transaction.tran_amount +
								'</strong><br>' +
								'Usuario: <strong>' +
								this.transaction.payer.name +
								' ' +
								this.transaction.payer.user_lastname +
								' | ' +
								this.transaction.payer.email +
								'</strong><br>' +
								'Comercio: <strong>' +
								this.transaction.owner.name +
								' ' +
								this.transaction.owner.user_lastname +
								'</strong><br>' +
								'Número de referencia: <strong>000' +
								this.transaction.id +
								'</strong> <br>' +
								'Estatus: <strong>' +
								status +
								'</strong></p>',
							showCancelButton: false,
							showConfirmButton: true,
						}).then((result) => {
							if (result.isConfirmed) {
								location.reload()
							}
						})
					})
					.catch((error) => {
						this.$swal({
							title: 'Ocurrió un problema',
							icon: 'error',
							showCancelButton: false,
							showConfirmButton: true,
						}).then((result) => {
							if (result.isConfirmed) {
								location.reload()
							}
						})
						this.$swal.showValidationMessage(`Solicitud rechazada: ${error}`)
						//console.log(error);
						this.error = true
					})
			},

			sendPayment(
				plat_id,
				owner_id,
				method,
				bank,
				date,
				reference,
				identifier,
				amount,
				email,
				description,
				currency,
				stripeToken,
				apiKey,
				accessToken
			) {
				let urlSendPayment = process.env.VUE_APP_PAYFORU_API + 'api/pfu-payment-process'

				let header = {
					headers: {
						Authorization: `Bearer ` + accessToken,
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
				}

				let request = {
					plat_id: plat_id,
					owner_id: owner_id,
					method: method,
					bank: bank,
					date: date,
					reference: reference,
					identifier: identifier,
					amount: amount,
					email: email,
					description: description,
					currency: currency,
					stripeToken: stripeToken,
					apiKey: apiKey,
				}

				axios
					.post(urlSendPayment, request, header)
					.then((response) => {
						//console.log(response.data);
						if (response.data.output.status != 'failed') {
							this.getTransaction(response.data.output.request_code)
						} else {
							throw new Error(JSON.stringify(response.data.output.errors))
						}
					})
					.catch((error) => {
						this.$swal({
							title: 'Ocurrió un problema',
							text: 'Compruebe sus datos e intente de nuevo',
							icon: 'error',
							showCancelButton: false,
							showConfirmButton: true,
						}).then((result) => {
							if (result.isConfirmed) {
								location.reload()
							}
						})
						this.$swal.showValidationMessage(`Solicitud rechazada: ${error}`)
						//console.log(error);
						this.error = true
					})
			},

			getErrorMessage(errors) {
				let message = ''
				errors.forEach((error) => {
					message = message + error + '<br>'
				})
				return message
			},

			showZelleData() {
				this.$swal({
					imageUrl:
						'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/67d2a181bd323da158eb4add0741241epng',
					imageWidth: 200,
					html:
						'<h5>Por favor realice su pago a la siguiente cuenta: <br>' +
						'<strong>LikeU Group Venezuela LLC<strong/> <br>' +
						'<strong>malllikeu123@gmail.com<strong/>',

					focusConfirm: true,
					confirmButtonText: 'Listo',
				})
			},

			paymentProcess(
				method,
				accessToken,
				amount,
				currency,
				description,
				owner_id,
				plat_id
			) {
				var respuesta = ''

				switch (method) {
					case 41:
						//INICIO DEL CODIGO PARA PROCESAR STRIPE

						this.$swal({
							imageUrl:
								'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/943ae50100338e50ece3d50e3a469670png',
							imageWidth: 200,
							html: `<div class="form-group">
	             <form action validate id="stripeForm" name="stripeForm">
	                   <label>Información de la tarjeta de crédito</label>
	                   <input
	                     id="cardNumber"
	                     name="cardNumber"
	                     type="number"
	                     oninput="if(this.value.length > 20){this.value = this.value.slice(0,20);}"
	                     class="form-control"
	                     min="1"
	                     pattern="^[0-9]+"
	                     placeholder="0000000000000000"
	                     v-model="card.number"
	                     required
	                   />
	                 </div>
	                 <div class="form-group">
	                   <input
	                     id="cardCVC"
	                     name="cardCVC"
	                     type="number"
	                     min="1"
	                     pattern="^[0-9]+"
	                     oninput="if(this.value.length > 3){this.value = this.value.slice(0,3);}"
	                     class="form-control"
	                     placeholder="CVC"
	                     v-model="card.cvc"
	                     required
	                   />
	                 </div>
	                 <div class="form-group">
	                   <label>Fecha de expiración</label>
	                   <input
	                     id="cardExp"
	                     name="cardExp"
	                     type="text"
	                     class="form-control"
	                     placeholder="MM/YY"
	                     v-model="card.exp"
	                     required
	                   />
	                 </div>
	               </form>
	             </div>`,
							focusConfirm: false,
							confirmButtonColor: '#003d74',
							showConfirmButton: true,
							confirmButtonText: 'Procesar',

							preConfirm: (login) => {
								this.validate()
							},
						})
							.then((result) => {
								if (result.isConfirmed) {
									this.stripeCheck = true
									window.Stripe.setPublishableKey(this.stripePublishableKey)
									window.Stripe.createToken(this.card, (status, response) => {
										this.stripeCheck = false
										if (response.error) {
											this.errors.push(`Stripe response:` + response.error.message)
											this.$swal({
												title: 'Datos inválidos',
												text: 'Transacción fallida. Por favor intente nuevamente',
												icon: 'error',
												showCancelButton: false,
												confirmButtonColor: '#003d74',
												showConfirmButton: true,
											}).then((result) => {
												if (result.isConfirmed) {
													location.reload()
												}
											})
											this.errorMessage = this.getErrorMessage(this.errors)
											this.$swal.showValidationMessage(
												`Solicitud rechazada:<br>${this.errorMessage}`
											)
										} else {
											this.sendPayment(
												plat_id,
												owner_id,
												'stripe',
												'stripe',
												new Date().toISOString(),
												'N/A',
												'N/A',
												amount,
												this.email,
												description,
												this.currency,
												response.id,
												'test',
												accessToken
											)
										}
									})
								}
							})
							.catch((error) => {
								this.$swal({
									title: 'Datos inválidos',
									text: 'Por favor intente nuevamente',
									confirmButtonColor: '#003d74',
									showConfirmButton: true,
									confirmButtonText: 'Aceptar',
								}).then((result) => {
									if (result.isConfirmed) {
										location.reload()
									}
								})
								console.log(error)
								this.$swal.showValidationMessage(`Request failed: ${error}`)
							})

						//FIN DEL CODIGO PARA PROCESAR STRIPE
						break

					case 46:
						//INICIO DEL CODIGO PARA PROCESAR ZELLE
						this.$swal({
							imageUrl:
								'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/67d2a181bd323da158eb4add0741241epng',
							imageWidth: 200,
							html:
								'<h5>Por favor realice su pago a la siguiente cuenta: <br>' +
								'<strong>LikeU Group Venezuela LLC<strong/> <br>' +
								'<strong>malllikeu123@gmail.com<strong/> <br>' +
								'Luego ingrese el código de confirmación<h5/>' +
								'<input id="amount" required placeholder="Monto" class="swal2-input" value="' +
								currency +
								' ' +
								amount +
								'" readonly>' +
								'<input id="date" type="date" required value="' +
								this.date +
								'" placeholder="Fecha del pago (DD/MM/YYYY)" pattern="\d{2}-\d{2}-\d{4}" class="swal2-input">' +
								'<input id="reference" required placeholder="Código de Confirmación" class="swal2-input">',

							focusConfirm: false,
							confirmButtonText: 'Procesar',
							showLoaderOnConfirm: true,

							preConfirm: (login) => {
								this.sendPayment(
									plat_id,
									owner_id,
									'zelle',
									'ZE_Zelle',
									document.getElementById('date').value,
									document.getElementById('reference').value,
									'N/A',
									amount,
									this.email,
									description,
									this.currency,
									'N/A',
									'test',
									accessToken
								)
							},
						})
						//FIN DEL CODIGO PARA PROCESAR ZELLE
						break
					case 43:
						// code block
						//INICIO DEL CODIGO PARA PROCESAR VIPPO
						;(async () => {
							const { value: formValues } = await this.$swal({
								html:
									'<img width="200px" src="https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/cba97f970f892d22426d4cac3920b302png">' +
									'<input id="swal-input1" placeholder="Usuario vippo" class="swal2-input">' +
									'<input id="swal-input2" placeholder="contrase&ntilde;a" class="swal2-input">' +
									'<input id="swal-input3" placeholder="monto" class="swal2-input" value="' +
									currency +
									' ' +
									amount +
									'" readonly>',
								focusConfirm: false,
								confirmButtonText: 'Procesar',
								showLoaderOnConfirm: true,

								preConfirm: (login) => {
									var myHeaders = new Headers()
									myHeaders.append('Accept', 'application/json')
									myHeaders.append('Content-Type', 'application/json')
									myHeaders.append('X-Requested-With', 'XMLHttpRequest')
									myHeaders.append('Authorization', 'Bearer ' + accessToken)

									var raw = JSON.stringify({
										method: 'vippo',
										bank: 'Vippo',
										date: new Date().toISOString(),
										identifier: 'N/A',
										amount: amount,
										user: document.getElementById('swal-input1').value,
										currency: 'VEF',
										description: 'Prueba',
										password: document.getElementById('swal-input2').value,
										reference: 'VP-111222',
										session: 'N/A',
										pin: 'N/A',
									})
									var requestOptions = {
										method: 'POST',
										headers: myHeaders,
										body: raw,
										redirect: 'follow',
									}
									return fetch(
										'https://apiqa.payfor-u.com/api/pfu-payment-process-auth',
										requestOptions
									)
										.then((response) => {
											if (!response.ok) {
												throw new Error(response.statusText)
											}
											return response.json()
										})
										.catch((error) => {
											this.$swal.showValidationMessage(`Request failed: ${error}`)
										})
								},
							})
							var usuario = document.getElementById('swal-input1').value
							if (formValues) {
								//INICIO DEL PROCESO DE ENVIAR DATOS DEL PAGO
								//FIN DEL PROCESO PARA ENVIAR LOS DATOS DEL PAGO
								var respuesta_referenca = ''

								respuesta = JSON.stringify(formValues.output.status)
								respuesta_referenca = formValues.output.request_code
								var respuesta2 = JSON.parse(formValues.output.status)
								var sesion_mardita = respuesta2.SessionToken

								if (respuesta2.success == true) {
									;(async () => {
										var nro_referencia = respuesta_referenca.slice(1, -1)

										const { value: formValues2 } = await this.$swal({
											title: 'Vippo',
											html:
												'<h5> Por favor ingrese su token de seguridad enviados a su email y su telefono. </>' +
												'<input id="swal-input2" placeholder="pin" class="swal2-input">',
											focusConfirm: false,
											confirmButtonText: 'Procesar',
											showLoaderOnConfirm: true,

											preConfirm: (login) => {
												var myHeaders = new Headers()
												myHeaders.append('Accept', 'application/json')
												myHeaders.append('Content-Type', 'application/json')
												myHeaders.append('X-Requested-With', 'XMLHttpRequest')
												myHeaders.append('Authorization', 'Bearer ' + accessToken)
												var raw = JSON.stringify({
													method: 'vippo',
													bank: 'Vippo',
													date: new Date().toISOString(),
													identifier: 'N/A',
													amount: amount,
													user: usuario,
													currency: 'VEF',
													description: 'Prueba',
													password: 'N/A',
													reference: respuesta_referenca,
													session: sesion_mardita,
													pin: document.getElementById('swal-input2').value,
												})
												var requestOptions = {
													method: 'POST',
													headers: myHeaders,
													body: raw,
													redirect: 'follow',
												}
												return fetch(
													'https://apiqa.payfor-u.com/api/pfu-payment-process',
													requestOptions
												)
													.then((response) => {
														if (!response.ok) {
															throw new Error(response.statusText)
														}
														return response.json()
													})
													.catch((error) => {
														this.$swal.showValidationMessage(`Request failed: ${error}`)
													})
											},
										})
										if (formValues2) {
											respuesta = JSON.stringify(formValues2.output.status)
											var nro_referencia = respuesta.slice(1, -1)
											//console.log(respuesta);
											//console.log(respuesta.success);
											if (nro_referencia == 'success') {
												this.$swal('Pago recibido', 'Hemos recibido tu pago', 'success')
											}
										}
									})()
								}
							}
						})()
						//FIN DEL CODIGO PARA PROCESAR VIPPO

						break
					case 44:
						//tarjeta de debito
						break
					case 45:
						//tarjeta de credito
						break
					case 47:
						//mpanco
						this.$swal({
							imageUrl:
								'https://www.mpandco.com/wp-content/uploads/2019/07/logo-mpandco-aprobado-nuevo_Artboard-4.png',
							imageWidth: 200,
							html:
								'<h5>Por favor realice su pago a la siguiente cuenta: <br>' +
								'<strong>Ingrese su pin de operaciones para autorizar el pago<strong/> <br>' +
								'<input id="user" required placeholder="Usuario" class="swal2-input">' +
								'<input id="pin" required placeholder="PIN" class="swal2-input">' +
								'<input id="amount" required placeholder="Monto" class="swal2-input" value="' +
								currency +
								' ' +
								amount +
								'" disabled>' +
								'<input id="reference" required placeholder="Referencia del pago" class="swal2-input">',
							focusConfirm: false,
							confirmButtonText: 'Procesar',
							showLoaderOnConfirm: true,

							preConfirm: (login) => {
								this.sendPayment(
									plat_id,
									owner_id,
									'banks',
									document.getElementById('bank').value,
									new Date().toISOString(),
									document.getElementById('reference').value,
									'N/A',
									amount,
									this.email,
									description,
									this.currency,
									'N/A',
									'test',
									accessToken
								)
							},
						})
						break

					case 49:
						//TR o DP Bs
						this.$swal({
							imageUrl:
								'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/5db08d20c6befd785e3f3a20d6de31d7png',
							imageWidth: 200,
							html:
								`<div class="form-group">
	                   <label>Información de la tarjeta de crédito</label>
	                   <h5>Por favor realice su pago a la siguiente cuenta: <br>
	                   <strong>LikeU Group Venezuela, C.A.<strong/> <br>
	                   <strong>Banco Venezolano de Credito: 0104-0107-16-0107170839<strong/> <br>
	                   <strong>Banco Nacional de Credito: 0191-0035-91-2135036621<strong/> <br>
	                   <strong>Banco Mercantil: 0105-0623-97-1623054796<strong/> <br>
	                   <strong>Banplus: 0174-0131-93-1314358332<strong/> <br>
	                 </div>
	                 <div class="form-group">
	                     <select  class="form-control" id="bank">
	                       <option value="BVC">Banco Venezolano de Credito</option>
	                       <option value="BNC">Banco Nacional de Credito</option>
	                       <option value="Mercantil">Banco Mercantil</option>
	                       <option value="Banplus" selected>Banplus</option>
	                     </select>
	                 </div>
	                 <div class="form-group">
	                   <input
	                     id="amount"
	                     required
	                     placeholder="Monto"
	                     class="swal2-input"
	                     value="` +
								currency +
								` ` +
								amount +
								`"
	                     disabled
	                   />
	                 </div>
	                 <div class="form-group">
	                   <label>Fecha del pago</label>
	                   <input
	                     id="date"
	                     type="date"
	                     required
	                     placeholder="DD/MM/YYYY"
	                     class="swal2-input"
	                   />
	                 </div>
	                 <div class="form-group">
	                   <label>Número de Referencia</label>
	                   <input
	                     id="reference"
	                     required
	                     class="swal2-input"
	                   />
	                 </div>
	             </div>`,
							focusConfirm: false,
							confirmButtonText: 'Procesar',
							showLoaderOnConfirm: true,

							preConfirm: (login) => {
								this.sendPayment(
									plat_id,
									owner_id,
									'banks',
									document.getElementById('bank').value,
									document.getElementById('date').value,
									document.getElementById('reference').value,
									'N/A',
									amount,
									this.email,
									description,
									this.currency,
									'N/A',
									'test',
									accessToken
								)
							},
						})
						break

					case 55: //Pago movil
						this.$swal({
							imageUrl:
								'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/pagomovil.png',
							imageWidth: 200,
							html:
								'<h5>Por favor realice su pago a la siguiente cuenta: <br>' +
								'<strong>LikeU Group Venezuela, C.A.<strong/> <br>' +
								'<strong>Telefono: 0414-3307233<strong/> <br>' +
								'<strong>RIF: J-405787877<strong/> <br>' +
								'Luego ingrese el código de confirmación<h5/>' +
								'<input id="date" type="date" required placeholder="Fecha del pago (DD/MM/YYYY)" class="swal2-input">' +
								'<input id="amount" required placeholder="Monto" class="swal2-input" value="' +
								currency +
								' ' +
								amount +
								'" disabled>' +
								'<input id="reference" required placeholder="Referencia del pago" class="swal2-input">',
							focusConfirm: false,
							confirmButtonText: 'Procesar',
							showLoaderOnConfirm: true,

							preConfirm: (login) => {
								this.sendPayment(
									plat_id,
									owner_id,
									'banks',
									'Pago_movil',
									document.getElementById('date').value,
									document.getElementById('reference').value,
									'N/A',
									amount,
									this.email,
									description,
									this.currency,
									'N/A',
									'test',
									accessToken
								)
							},
						})
						break

					default:
						this.$swal({
							title: 'Método de pago no disponible...',
							text: 'Por favor, selecciona otro método de pago',
							icon: 'info',
							showCancelButton: false,
							showConfirmButton: true,
						}).then((result) => {
							if (result.isConfirmed) {
								location.reload()
							}
						})
						break
				}
				return respuesta
			},
		},
	}
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/main.scss';
	.ht-btn--round {
		padding: 10px;
		padding-top: 5px;
		padding-bottom: 5px;
		margin-top: 20px;
		font-size: 20px;
		background-color: #003d74;
	}

	.avatar {
		width: 100%;
		position: relative;
		margin: auto;
		margin-top: 15px;
		margin-bottom: 15px;
		z-index: 100;
	}

	.avatar img {
		width: 100px;
		height: 100px;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		border: 3px solid rgba(0, 61, 116, 0.8);
	}

	.paymentWrap .paymentBtnGroup {
		width: 100%;
		margin: auto;
		display: flex;
		align-items: center;
	}

	.paymentWrap .paymentBtnGroup .paymentMethod {
		padding: 40px;
		box-shadow: none;
		position: relative;
	}

	.paymentWrap .paymentBtnGroup .paymentMethod.active {
		outline: none !important;
	}

	.paymentWrap .paymentBtnGroup .paymentMethod.active .method {
		border-color: #4cd264;
		outline: none !important;
		box-shadow: 0px 3px 22px 0px #7b7b7b;
	}

	.paymentWrap .paymentBtnGroup .paymentMethod .method {
		position: absolute;
		right: 3px;
		top: 3px;
		bottom: 3px;
		left: 3px;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		border: 2px solid transparent;
		transition: all 0.5s;
	}

	.banner {
		background-color: #003d7454;
		display: flex;
		justify-content: center;
		background-size: cover;
		align-items: center;
		width: 100%;
		height: 150px;
	}

	.banner::selection {
		background: #000;
	}

	.active {
		border: inset;
	}

	.swal2-image {
		max-width: 100%;
		/* margin: 1.25em auto; */
	}
</style>
