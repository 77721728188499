<template>
  <div class="main-container">
    <Header />

    <PaySection />

    <BrandCarousel />

    <Footer />

    <OffCanvasMobileMenu />

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import Header from "@/components/Header";
import Breadcrumb from "../components/Breadcrumb";
import PaySection from "@/components/sections/PaySection";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";

import data from "../data/service.json";
export default {
  data() {
    return {
      data,
      items: [
        {
          text: "Inicio",
          to: "/"
        },
        {
          text: "Pagar",
          active: true
        }
      ]
    };
  },
  components: {
    Header,
    Breadcrumb,
    PaySection,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu
  }
};
</script>
